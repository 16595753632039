import {
    HydrationBoundary,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { hydrateRoot } from 'react-dom/client';
import { loadAndActivateI18n } from './i18n.ts';
import Ads from './ad/Ads.tsx';
import { AppConfigProvider } from './utils/AppConfig.tsx';

const wrapper = document.querySelector(`recommendation-podlet-isolated`);

if (wrapper?.shadowRoot) {
    const data = wrapper.shadowRoot.querySelector(
        `script[type="application/json"]`,
    );
    if (data && data.textContent) {
        const { reactQueryState, ...props } = JSON.parse(data.textContent);
        const queryClient = new QueryClient({
            defaultOptions: {
                queries: {
                    refetchOnWindowFocus: false,
                },
            },
        });

        const { locale } = props.appConfig;
        loadAndActivateI18n(locale);

        const element = wrapper.shadowRoot.querySelector(
            `#recommendation-podlet`,
        );

        if (element) {
            hydrateRoot(
                element,
                <QueryClientProvider client={queryClient}>
                    <HydrationBoundary state={reactQueryState}>
                        <AppConfigProvider {...props.appConfig}>
                            <Ads {...props} />
                        </AppConfigProvider>
                    </HydrationBoundary>
                </QueryClientProvider>,
            );
        }
    }
}
