import React from 'react';
import { Item } from '../../data/recommendationDataTypes.ts';

type ImageWrapperProps = {
    item: Item;
    cdnBaseUrl: string;
};

const ImageWrapper: React.FC<ImageWrapperProps> = ({ item, cdnBaseUrl }) => {
    const image = item.image || { url: '', type: '', isAbsolute: false };
    const {
        url: imageUrl,
        type: imageType,
        isAbsolute: imageIsAbsolute,
    } = image;

    const isLogo = imageType === 'LOGO';
    const imageClass = isLogo
        ? 'w-full h-full object-center object-scale-down m-auto sm:h-auto'
        : 'w-full h-full object-center m-auto absolute inset-0';

    const imageSrc =
        imageIsAbsolute || imageType === 'EXTERNAL'
            ? imageUrl
            : `${cdnBaseUrl}/${imageUrl}`;

    return (
        <div className="aspect-square overflow-hidden border-b s-bg hover:s-bg-hover active:s-bg-active relative">
            {isLogo || item.type === 'REALESTATE' ? (
                <img
                    alt=""
                    loading="lazy"
                    className={`${imageClass} object-cover`}
                    sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
                    src={imageSrc}
                    style={{
                        maxWidth: isLogo ? '65%' : '100%',
                        maxHeight: isLogo ? '65%' : '100%',
                    }}
                />
            ) : (
                <div className="absolute inset-0 w-full">
                    <img
                        alt=""
                        loading="lazy"
                        className="absolute inset-0 h-full w-full object-cover"
                        sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
                        src={imageSrc}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                        }}
                    />
                    <img
                        alt=""
                        loading="lazy"
                        className={`${imageClass} object-contain`}
                        sizes="(min-width: 1300px) 303px, (min-width: 1010px) 313px, (min-width: 768px) 33vw, (min-width: 480px) 50vw, 100vw"
                        src={imageSrc}
                        style={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            backdropFilter: 'blur(24px)',
                        }}
                    />
                </div>
            )}
            {item?.type?.toUpperCase() !== 'JOB' && (
                <span>
                    <div
                        className="absolute bottom-0 font-bold s-text-inverted-static p-12 custom-label-style"
                        title="Legg til favoritt"
                    >
                        {item.label}
                    </div>
                </span>
            )}
        </div>
    );
};

export default ImageWrapper;
