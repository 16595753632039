import { useEffect, useState } from 'react';
import { useInfiniteScrollFetchMoreData } from './recommendationData.ts';
import { RecommendationData } from './recommendationDataTypes.ts';
import { InfiniteData } from '@tanstack/react-query';
import * as React from 'react';
import { useInscreenObserver } from '../tracking/useInscreenObserver.ts';
import { useRef } from 'react';

type InfiniteScrollFetchMoreDataProps = {
    apiUrl?: string;
    ref?: React.MutableRefObject<Element | null>;
    recommender?: string;
    position?: string;
    fetchMoreUrls?: string[];
};

export function useInfiniteFetchMoreData(
    props: InfiniteScrollFetchMoreDataProps,
) {
    const { apiUrl, ref, recommender, position, fetchMoreUrls } = props;
    const {
        fetchMoreData,
        isPersonal,
        fetchNextPage,
        isFetched,
        hasMoreFetchData,
    } = useInfiniteScrollFetchMoreData(
        apiUrl,
        recommender,
        position,
        fetchMoreUrls,
    );
    const [currentFetchMoreData, setCurrentFetchMoreData] = useState<
        InfiniteData<RecommendationData>
    >({
        pages: [],
        pageParams: [],
    });

    useEffect(
        function updateCurrentFetchMoreData() {
            if (fetchMoreData && fetchMoreData !== currentFetchMoreData) {
                setCurrentFetchMoreData(fetchMoreData);
            }
        },
        [fetchMoreData, currentFetchMoreData],
    );

    const debouncedFetchNextPage = useDebouncedFetchNextPage(fetchNextPage);

    useInscreenObserver(
        ref,
        () => {
            if (hasMoreFetchData) {
                debouncedFetchNextPage();
            }
        },
        {
            threshold: 0,
            once: true,
        },
    );

    return {
        currentFetchMoreData,
        isPersonal,
        isFetched,
        hasMoreFetchData,
    };
}

const useDebouncedFetchNextPage = (fetchNextPage, delay = 100) => {
    const timeoutRef = useRef(null);

    const debouncedFetchNextPage = () => {
        if (timeoutRef.current) clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
            fetchNextPage();
        }, delay);
    };

    return debouncedFetchNextPage;
};
